
@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap');/*

.App{
  color: rgb(0, 0, 0);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  background-color: rgb(255, 255, 255);
  
}

.navbar{
  width: 100%;
  height: 5%;
  background-color: aqua;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar ul{
  list-style-type: none;
  color: rgb(0, 86, 156)
}


a{
  color: rgb(212, 32, 32);
  text-decoration: underline ;
  text-decoration-thickness: .5px;
}

a:hover{
  color: rgb(134, 31, 31);
}


*/